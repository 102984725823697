const teamData = {
  developer: [
    {
      company: 'L + M Development Partners',
      role: 'co-developer',
      blurb: 'Since its inception in 1984, L+M Development Partners Inc. has been an innovator in developing quality residential, commercial, retail and community facilities.  L+M is responsible for more than $7 billion in development, construction and investment, and has created or preserved more than 22,000 high-quality residential units in New York’s Tristate area, the West Coast and Gulf Coast regions. L+M, along with two partners, is currently developing Essex Crossing, an unprecedented 1.9 million square foot retail, office, green and community development in the heart of Manhattan’s Lower East Side.  A full-service firm, L+M works from conception to completion, handling development, investment, construction and management with creativity that leads the industry.',
      mobileHeight: {
        total: 600,
        top: 250,
        bottom: 350
      }
    },
    {
      company: 'J & R Music World',
      role: 'co-developer',
      blurb: 'J&R Development is the expansion of Rachelle and Joe Friedman’s long and renowned business presence in New York City, which began in 1971 when they opened J&R Music World on Park Row. In the decades that followed they expanded to create J&R Music and Computer World, the largest independently owned music, computer and electronics mega-store in the country. As part of that business development, they began purchasing the real estate of their retail locations along Park Row, including the 1899 landmark, 15 Park Row, which they have now converted to mostly residences. They developed One Park Row in the 1990s, and now are converting much of that structure to condominiums. Rachelle, president and co-CEO of J&R, has won numerous awards from business and civic groups, and currently serves on the Advisory Board of the Lower Manhattan Development Corporation, the Regional Advisory Board of JPMorgan Chase, and the Better Business Board of Greater New York, NYC.',
      mobileHeight: {
        total: 650,
        top: 250,
        bottom: 400
      }
    }
  ],
  design: [
    {
      company: 'Cookfox Architects',
      role: 'Architectural Design',
      blurb: 'COOKFOX Architects is a New York City based architectural and interior design studio. COOKFOX has built a studio focused on high-performance, environmentally responsive design and is well-known for innovative design at the highest standards of environmental performance. COOKFOX focuses on biophilic design to create architecture that supports physical health and mental wellness. With a portfolio of diverse residential, workplace, and education projects, COOKFOX seeks to pursue architecture that restores, regenerates, and elevates our collective experience of the urban environment.',
      mobileHeight: {
        total: 550,
        top: 250,
        bottom: 300
      }
    },
    {
      company: 'Studio Mellone',
      role: 'Amenity Design',
      blurb: 'Andre Mellone founded Studio Mellone in 2012 to expand his unique vision which was cultivated during a 20-year career in New York City’s design community, working with Robert A. M. Stern, Mark Hampton, Greg Jordan, Ferguson&Shamamian and Sawyer/Berson. Mellone primarily focuses on private homes and residences, along with boutique retail. He combines the finest architectural interior work with an expertise in crafting custom furniture and textiles to create dramatic visual cues within an elegant framework. Architectural Digest has called him a “world famous designer with a trademark eye” who creates “midcentury, masculine decors for the likes of [fashion designers] Thom Browne and Jason Wu.”',
      mobileHeight: {
        total: 600,
        top: 250,
        bottom: 350
      }
    },
    {
      company: `Starr Whitehouse \n Landscape Architects and Planners`,
      role: 'Landscape Design',
      blurb: 'Starr Whitehouse has been re-imagining New York’s most iconic landscapes for over three decades. As Chief of Design for the Central Park Conservancy, Principal and Co-founder Laura Starr oversaw the renovation of Central Park in the ’80s and ’90s, teaming with Studio Director Jeffrey Poor to reconstruct major destinations such as the Harlem Meer and Great Lawn and cultivated a national rediscovery of the world’s most famous park. On the southern tip of Manhattan, Starr and Poor have been collaborating with the Battery Conservancy since its founding, transforming the lot at the foot of Manhattan into a world class urban park that welcomes millions every year. They have won awards from New York City government and the national design communities, including for their boutique residential projects.',
      mobileHeight: {
        total: 650,
        top: 250,
        bottom: 400
      }
    }
  ],
  marketing: [
    {
      company: 'DBOX',
      role: 'Marketing + Creative',
      blurb: 'We build brands and develop immersive marketing campaigns for the fields of property development, architecture and the arts. Since being founded in 1996, we have received awards from the Academy of Television Arts & Sciences (Emmy® Awards), The Art Directors Club, World Luxury Award, American Design Awards, Communication Arts and The International Property Awards, and our work has been exhibited at some of the world’s most respected art institutions, such as The Museum of Modern Art (MoMA), The Museum of Contemporary Art in Tokyo, The Art Institute of Chicago, New Museum and The Museum of Contemporary Art in Los Angeles.',
      mobileHeight: {
        total: 550,
        top: 250,
        bottom: 300
      }
    },
    {
      company: 'Corcoran Sunshine Marketing Group',
      role: 'Exclusive Marketing',
      blurb: 'With over 30 years of experience in marketing and collective sales in excess of $40 billion, Corcoran Sunshine Marketing Group is the recognized industry leader in the planning, design, marketing, and sale of luxury residential development. Representing properties throughout the United States and in select international locations, Corcoran Sunshine Marketing Group’s portfolio contains a curated collection of the world’s most desirable new addresses.',
      mobileHeight: {
        total: 500,
        top: 250,
        bottom: 250
      }
    }
  ]
};

export default teamData;