export const pageTitleMap = {
  '/': 'Home',
  '/architecture': 'Architecture',
  '/interiors': 'Interiors',
  '/amenities': 'Amenities',
  '/views': 'Views',
  '/floorplans': 'Floor Plans',
  '/neighborhood': 'Neighborhood',
  '/gallery': 'Gallery',
  '/contact': 'Contact Us',
  '/brochure': 'Brochure',
  '/press': 'Press',
  '/team': 'Team',
  '/legal': 'Legal',
  '/accessibility-statement': 'Accessibility Statement'
};
