const pages = {
  architecture: 'Architecture',
  interiors: 'Interiors',
  amenities: 'Amenities',
  views: 'Views',
  floorplans: 'Floor Plans',
  neighborhood: 'Neighborhood',
  gallery: 'Gallery',
  contact: 'Contact Us'
};
export const subPages = {
  brochure: 'Brochure',
  press: 'Press',
  team: 'Team',
  legal: 'Legal',
  instagram: 'Instagram'
};

export default pages;
