export const colorVars = {
  olive: '#C5C9AE',
  paleBlue: '#B6C1C1',
  grey: '#D8D8D8',
  forestGreen: '#787A62',
  lightOlive: '#d0d5bf',
  darkOlive: '#91937E',
  lightGrey: '#E1E5E5',
  charcoal: '#989898',
  floorplanBlue: '#B4C0BE',
  floorPlanBlueTransparent: 'rgba(180, 192, 190, .2)'
};

const colors = {
  home: {
    backgroundColor: '#FFF',
    color: 'black',
    mobileHeader: { background: '#D8D8D8' },
    sliderColors: { background: '#C5C9AE', activeColor: 'black' }
  },
  architecture: {
    backgroundColor: '#E7E8DD',
    color: 'black',
    mobileHeader: { background: '#91937F' },
    sliderColors: { background: '#C5C9AE', activeColor: 'black' }
  },
  interiors: {
    backgroundColor: '#E1E5E5',
    color: 'black',
    mobileHeader: { background: '#B6C1C1' },
    sliderColors: { background: '#B6C1C1', activeColor: 'black' }
  },
  amenities: {
    backgroundColor: '#E7E8DD',
    color: 'black',
    mobileHeader: { background: '#C5C9AE' },
    sliderColors: { background: '#C5C9AE', activeColor: 'black' }
  },
  views: {
    backgroundColor: '#D8D8D8',
    color: 'black',
    mobileHeader: { background: '#D8D8D8' },
    sliderColors: { background: '#989898', activeColor: 'black' }
  },
  floorplans: {
    backgroundColor: '#FFF',
    color: 'black',
    mobileHeader: { background: '#D8D8D8' },
    sliderColors: { background: '#b4c0be', activeColor: 'black' }
  },
  neighborhood: {
    backgroundColor: '#C6C7BB',
    color: 'black',
    mobileHeader: { background: '#C6C7BB' },
    sliderColors: { background: '#91937F', activeColor: 'black' },
    footerBackground: 'white'
  },
  team: {
    backgroundColor: '#D8D8D8',
    color: 'black',
    mobileHeader: { background: '#D8D8D8' },
    sliderColors: { background: '#000', activeColor: 'white' }
  },
  gallery: {
    backgroundColor: '#FFF',
    color: 'black',
    mobileHeader: { background: '#D8D8D8' },
    sliderColors: { background: '#C5C9AE', activeColor: 'black' }
  },
  contact: {
    backgroundColor: '#636551',
    color: 'white',
    mobileHeader: { background: '#FFFFFF' },
    sliderColors: { background: '#FFF', activeColor: 'black' }
  },
  brochure: {
    backgroundColor: '#000',
    color: 'white',
    mobileHeader: { background: '#000' }
  },
  press: {
    backgroundColor: '#B6C1C1',
    color: 'black',
    mobileHeader: { background: '#D8D8D8' }
  },
  legal: {
    backgroundColor: '#B6C1C1',
    color: 'black',
    mobileHeader: { background: '#D8D8D8' }
  },
  'accessibility-statement': {
    backgroundColor: '#fff',
    color: 'black',
    mobileHeader: { background: '#B6C1C1' }
  },
  notfoundpage: {
    backgroundColor: '#FFF',
    color: 'black',
    mobileHeader: { background: '#FFF' }
  }
};

export default colors;
